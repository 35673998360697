import React, { useContext, useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import GridRow from './_grid-row'
import { Container, Grid } from '@material-ui/core'
import styles from '../assets/scss/calander.module.scss'
import ApiContext from '../providers/api'
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
const spinner = css`
  display: block;
  margin: 0 auto;
`;


export default function Calander({class_title, class_description}) {

    const { isLoading } = useContext(ApiContext);
    // const [eventsArray, setEventsArray] = useState();
    // useEffect(async () => {
    //     const eventRes = await getEventsInfo();
    //     let resFormated = eventRes.map(eventsitem => (
    //         { title: eventsitem.name, start: eventsitem.start_time, end: eventsitem.end_time, extendedProps: { coachName: eventsitem.Coach? eventsitem.Coach.name : "" } }
    //     ))
    //     setEventsArray(resFormated);
    // }, []);
    

    // eventsi.map(eventsitem => ({ title: eventsitem.name, start: eventsitem.start_date, end: eventsitem.end_date, url: 'class'}))
    //#ea8b53 orange #58bcc1 terquaze
    const eventsArray = [
        //Sa7l
        //week 1 
        { title: 'Fitness Fundamentals - Sahel', start: "2021-08-01T10:00:00", end: "2021-08-01T11:00:00", url: '/class/21', color: "#58bcc1" },
        { title: 'Funk Flow - Sahel', start: "2021-08-01T11:15:00", end: "2021-08-01T12:15:00", url: '/class/26', color: "#58bcc1" },
        { title: 'Kids Fitness - Sahel', start: "2021-08-01T12:30:00", end: "2021-08-01T13:30:00", url: '/class/61', color: "#58bcc1" },
        
        { title: 'HIIT - Sahel', start: "2021-08-02T10:00:00", end: "2021-08-02T11:00:00", url: '/class/19', color: "#58bcc1" },
        
        { title: 'WORKSHOP - Sahel', start: "2021-08-03T10:00:00", end: "2021-08-03T13:30:00", url: '/class/37', color: "#58bcc1" },
        
        { title: 'HOT IRON - Sahel', start: "2021-08-04T10:00:00", end: "2021-08-04T11:00:00", url: '/class/18', color: "#58bcc1" },
        { title: 'Kids Gymnastics - Sahel', start: "2021-08-04T12:30:00", end: "2021-08-04T13:30:00", url: '/class/62', color: "#58bcc1" },
        
        { title: 'Playground Yoga - Sahel', start: "2021-08-05T10:00:00", end: "2021-08-05T11:00:00", url: '/class/28', color: "#58bcc1" },
        { title: 'Speed and Agility - Sahel', start: "2021-08-05T11:15:00", end: "2021-08-05T12:15:00", url: '/class/20', color: "#58bcc1" },
        
        { title: 'SPINTONIC - Sahel', start: "2021-08-06T10:00:00", end: "2021-08-06T11:00:00", url: '/class/33', color: "#58bcc1" },
        { title: 'HOT IRON - Sahel', start: "2021-08-06T11:15:00", end: "2021-08-06T12:15:00", url: '/class/18', color: "#58bcc1" },
        { title: 'Kids Fitness - Sahel', start: "2021-08-06T12:30:00", end: "2021-08-06T13:30:00", url: '/class/61', color: "#58bcc1" },
        
        { title: 'SPINNING - Sahel', start: "2021-08-07T10:00:00", end: "2021-08-07T11:00:00", url: '/class/32', color: "#58bcc1" },
        { title: 'Playground Yoga - Sahel', start: "2021-08-07T11:15:00", end: "2021-08-07T12:15:00", url: '/class/28', color: "#58bcc1" },
        //week 2
        { title: 'Pilates - Sahel', start: "2021-08-08T10:00:00", end: "2021-08-08T11:00:00", url: '/class/25', color: "#58bcc1" },
        { title: 'SPINTONIC - Sahel', start: "2021-08-08T11:15:00", end: "2021-08-08T12:15:00", url: '/class/33', color: "#58bcc1" },
        { title: 'Kids Fitness - Sahel', start: "2021-08-08T12:30:00", end: "2021-08-08T13:30:00", url: '/class/61', color: "#58bcc1" },
        
        { title: 'HOT IRON - Sahel', start: "2021-08-09T10:00:00", end: "2021-08-09T11:00:00", url: '/class/18', color: "#58bcc1" },
        
        
        { title: 'SPINNING - Sahel', start: "2021-08-11T10:00:00", end: "2021-08-11T11:00:00", url: '/class/32', color: "#58bcc1" },
        { title: 'Kids Gymnastics - Sahel', start: "2021-08-11T12:30:00", end: "2021-08-11T13:30:00", url: '/class/62', color: "#58bcc1" },
        
        { title: 'HIIT - Sahel', start: "2021-08-12T10:00:00", end: "2021-08-12T11:00:00", url: '/class/19', color: "#58bcc1" },
        { title: 'Yin Yoga - Sahel', start: "2021-08-12T11:15:00", end: "2021-08-12T12:15:00", url: '/class/16', color: "#58bcc1" },
        { title: 'Kids Fitness - Sahel', start: "2021-08-12T12:30:00", end: "2021-08-12T13:30:00", url: '/class/61', color: "#58bcc1" },
        
        { title: 'HOT IRON - Sahel', start: "2021-08-13T10:00:00", end: "2021-08-13T11:00:00", url: '/class/18', color: "#58bcc1" },
        { title: 'Spinning Marathon - Sahel', start: "2021-08-13T11:15:00", end: "2021-08-13T13:30:00", url: '/class/55', color: "#58bcc1" },
        
        { title: 'Speed and Agility - Sahel', start: "2021-08-14T10:00:00", end: "2021-08-14T11:00:00", url: '/class/20', color: "#58bcc1" },
        { title: 'Pilates - Sahel', start: "2021-08-14T11:15:00", end: "2021-08-14T12:15:00", url: '/class/25', color: "#58bcc1" },
        //week 3
        { title: 'SPINTONIC - Sahel', start: "2021-08-15T10:00:00", end: "2021-08-15T11:00:00", url: '/class/33', color: "#58bcc1" },
        { title: 'Yin Yoga - Sahel', start: "2021-08-15T11:15:00", end: "2021-08-15T12:15:00", url: '/class/16', color: "#58bcc1" },
        { title: 'Kids Fitness - Sahel', start: "2021-08-15T12:30:00", end: "2021-08-15T13:30:00", url: '/class/61', color: "#58bcc1" },
        
        { title: 'Funk Flow - Sahel', start: "2021-08-16T10:00:00", end: "2021-08-16T11:00:00", url: '/class/26', color: "#58bcc1" },
        
        { title: 'WORKSHOP - Sahel', start: "2021-08-17T10:00:00", end: "2021-08-17T13:30:00", url: '/class/37', color: "#58bcc1" },
        
        { title: 'Fitness Fundamentals - Sahel', start: "2021-08-18T10:00:00", end: "2021-08-18T11:00:00", url: '/class/21', color: "#58bcc1" },
        { title: 'Kids Gymnastics - Sahel', start: "2021-08-18T12:30:00", end: "2021-08-18T13:30:00", url: '/class/62', color: "#58bcc1" },
        
        { title: 'Speed and Agility - Sahel', start: "2021-08-19T10:00:00", end: "2021-08-19T11:00:00", url: '/class/20', color: "#58bcc1" },
        { title: 'HOT IRON - Sahel', start: "2021-08-19T11:15:00", end: "2021-08-19T12:15:00", url: '/class/18', color: "#58bcc1" },
        
        { title: 'Masyaf Fitness Fiesta 2021 - Sahel', start: "2021-08-20T10:00:00", end: "2021-08-20T13:30:00", url: '/class/1', color: "#58bcc1" },
        
        { title: 'SPINNING - Sahel', start: "2021-08-21T10:00:00", end: "2021-08-21T11:00:00", url: '/class/32', color: "#58bcc1" },
        { title: 'Playground Yoga - Sahel', start: "2021-08-21T11:15:00", end: "2021-08-21T12:15:00", url: '/class/28', color: "#58bcc1" },
        // Gouna 26 Aug
        //Sunday
        { title: 'Ride & Run - Gouna', start: "2021-08-01T08:30:00", end: "2021-08-01T09:30:00", url: '/class/63', color: "#ea8b53" },
        { title: 'Yoga - Gouna', start: "2021-08-01T10:00:00", end: "2021-08-01T11:00:00", url: '/class/64', color: "#ea8b53" },
        { title: 'Hip Hop - Gouna', start: "2021-08-01T18:30:00", end: "2021-08-01T19:30:00", url: '/class/65', color: "#ea8b53" },
        //Monday
        { title: 'Functional Strength - Gouna', start: "2021-08-02T08:30:00", end: "2021-08-02T09:30:00", url: '/class/66', color: "#ea8b53" },
        { title: 'Zumba - Gouna', start: "2021-08-02T10:00:00", end: "2021-08-02T11:00:00", url: '/class/31', color: "#ea8b53" },
        { title: 'Pound - Gouna', start: "2021-08-02T17:30:00", end: "2021-08-02T18:30:00", url: '/class/67', color: "#ea8b53" },
        { title: 'MMA - Gouna', start: "2021-08-02T18:30:00", end: "2021-08-02T19:30:00", url: '/class/68', color: "#ea8b53" },
        //Tuesday
        { title: 'Yoga - Gouna', start: "2021-08-03T08:30:00", end: "2021-08-03T09:30:00", url: '/class/64', color: "#ea8b53" },
        { title: 'SPINNING - Gouna', start: "2021-08-03T10:00:00", end: "2021-08-03T11:00:00", url: '/class/32', color: "#ea8b53" },
        { title: 'Boxercise - Gouna', start: "2021-08-03T17:30:00", end: "2021-08-03T18:30:00", url: '/class/69', color: "#ea8b53" },
        //Wednesday
        { title: 'Pound - Gouna', start: "2021-08-04T10:00:00", end: "2021-08-04T11:00:00", url: '/class/67', color: "#ea8b53" },
        { title: 'HIIT - Gouna', start: "2021-08-04T17:30:00", end: "2021-08-04T18:30:00", url: '/class/19', color: "#ea8b53" },
        { title: 'Hip Hop - Gouna', start: "2021-08-04T18:30:00", end: "2021-08-04T19:30:00", url: '/class/65', color: "#ea8b53" },
        //Thursday
        { title: 'Ride & Run - Gouna', start: "2021-08-05T08:30:00", end: "2021-08-05T09:30:00", url: '/class/63', color: "#ea8b53" },
        { title: 'Yoga - Gouna', start: "2021-08-05T10:00:00", end: "2021-08-05T11:00:00", url: '/class/64', color: "#ea8b53" },
        //Friday
        { title: 'GHM Training Program - Gouna', start: "2021-08-06T08:30:00", end: "2021-08-06T09:30:00", url: '/class/71', color: "#ea8b53" },
        { title: 'Personal Traingin & Customized Classes - Gouna', start: "2021-08-06T10:00:00", end: "2021-08-06T19:30:00", url: '/class/70', color: "#ea8b53" },
        //Saturday
        { title: 'Yoga - Gouna', start: "2021-08-07T10:00:00", end: "2021-08-07T11:00:00", url: '/class/64', color: "#ea8b53" },
        { title: 'Boxing - Gouna', start: "2021-08-07T17:30:00", end: "2021-08-07T18:30:00", url: '/class/34', color: "#ea8b53" },
        { title: 'MMA - Gouna', start: "2021-08-07T18:30:00", end: "2021-08-07T19:30:00", url: '/class/68', color: "#ea8b53" },
        //Sunday
        { title: 'Ride & Run - Gouna', start: "2021-08-08T08:30:00", end: "2021-08-08T09:30:00", url: '/class/63', color: "#ea8b53" },
        { title: 'Yoga - Gouna', start: "2021-08-08T10:00:00", end: "2021-08-08T11:00:00", url: '/class/64', color: "#ea8b53" },
        { title: 'Hip Hop - Gouna', start: "2021-08-08T18:30:00", end: "2021-08-01T19:30:00", url: '/class/65', color: "#ea8b53" },
        //Monday
        { title: 'Functional Strength - Gouna', start: "2021-08-09T08:30:00", end: "2021-08-09T09:30:00", url: '/class/66', color: "#ea8b53" },
        { title: 'Zumba - Gouna', start: "2021-08-09T10:00:00", end: "2021-08-09T11:00:00", url: '/class/31', color: "#ea8b53" },
        { title: 'Pound - Gouna', start: "2021-08-09T17:30:00", end: "2021-08-09T18:30:00", url: '/class/67', color: "#ea8b53" },
        { title: 'MMA - Gouna', start: "2021-08-09T18:30:00", end: "2021-08-09T19:30:00", url: '/class/68', color: "#ea8b53" },
        //Tuesday
        { title: 'Yoga - Gouna', start: "2021-08-10T08:30:00", end: "2021-08-10T09:30:00", url: '/class/64', color: "#ea8b53" },
        { title: 'SPINNING - Gouna', start: "2021-08-10T10:00:00", end: "2021-08-10T11:00:00", url: '/class/32', color: "#ea8b53" },
        { title: 'Boxercise - Gouna', start: "2021-08-10T17:30:00", end: "2021-08-10T18:30:00", url: '/class/69', color: "#ea8b53" },
        //Wednesday
        { title: 'Pound - Gouna', start: "2021-08-11T10:00:00", end: "2021-08-11T11:00:00", url: '/class/67', color: "#ea8b53" },
        { title: 'HIIT - Gouna', start: "2021-08-11T17:30:00", end: "2021-08-11T18:30:00", url: '/class/19', color: "#ea8b53" },
        { title: 'Hip Hop - Gouna', start: "2021-08-11T18:30:00", end: "2021-08-11T19:30:00", url: '/class/65', color: "#ea8b53" },
        //Thursday
        { title: 'Ride & Run - Gouna', start: "2021-08-12T08:30:00", end: "2021-08-12T09:30:00", url: '/class/63', color: "#ea8b53" },
        { title: 'Yoga - Gouna', start: "2021-08-12T10:00:00", end: "2021-08-12T11:00:00", url: '/class/64', color: "#ea8b53" },
        //Friday
        { title: 'GHM Training Program - Gouna', start: "2021-08-13T08:30:00", end: "2021-08-13T09:30:00", url: '/class/71', color: "#ea8b53" },
        { title: 'Personal Traingin & Customized Classes - Gouna', start: "2021-08-13T10:00:00", end: "2021-08-13T19:30:00", url: '/class/70', color: "#ea8b53" },
        //Saturday
        { title: 'Yoga - Gouna', start: "2021-08-14T10:00:00", end: "2021-08-14T11:00:00", url: '/class/64', color: "#ea8b53" },
        { title: 'Boxing - Gouna', start: "2021-08-14T17:30:00", end: "2021-08-14T18:30:00", url: '/class/34', color: "#ea8b53" },
        { title: 'MMA - Gouna', start: "2021-08-14T18:30:00", end: "2021-08-14T19:30:00", url: '/class/68', color: "#ea8b53" },
        //Sunday
        { title: 'Ride & Run - Gouna', start: "2021-08-15T08:30:00", end: "2021-08-15T09:30:00", url: '/class/63', color: "#ea8b53" },
        { title: 'Yoga - Gouna', start: "2021-08-15T10:00:00", end: "2021-08-15T11:00:00", url: '/class/64', color: "#ea8b53" },
        { title: 'Hip Hop - Gouna', start: "2021-08-15T18:30:00", end: "2021-08-15T19:30:00", url: '/class/65', color: "#ea8b53" },
        //Monday
        { title: 'Functional Strength - Gouna', start: "2021-08-16T08:30:00", end: "2021-08-16T09:30:00", url: '/class/66', color: "#ea8b53" },
        { title: 'Zumba - Gouna', start: "2021-08-16T10:00:00", end: "2021-08-16T11:00:00", url: '/class/31', color: "#ea8b53" },
        { title: 'Pound - Gouna', start: "2021-08-16T17:30:00", end: "2021-08-16T18:30:00", url: '/class/67', color: "#ea8b53" },
        { title: 'MMA - Gouna', start: "2021-08-16T18:30:00", end: "2021-08-16T19:30:00", url: '/class/68', color: "#ea8b53" },
        //Tuesday
        { title: 'Yoga - Gouna', start: "2021-08-017T08:30:00", end: "2021-08-017T09:30:00", url: '/class/64', color: "#ea8b53" },
        { title: 'SPINNING - Gouna', start: "2021-08-017T10:00:00", end: "2021-08-017T11:00:00", url: '/class/32', color: "#ea8b53" },
        { title: 'Boxercise - Gouna', start: "2021-08-017T17:30:00", end: "2021-08-17T18:30:00", url: '/class/69', color: "#ea8b53" },
        //Wednesday
        { title: 'Pound - Gouna', start: "2021-08-18T10:00:00", end: "2021-08-18T11:00:00", url: '/class/67', color: "#ea8b53" },
        { title: 'HIIT - Gouna', start: "2021-08-18T17:30:00", end: "2021-08-18T18:30:00", url: '/class/19', color: "#ea8b53" },
        { title: 'Hip Hop - Gouna', start: "2021-08-18T18:30:00", end: "2021-08-18T19:30:00", url: '/class/65', color: "#ea8b53" },
        //Thursday
        { title: 'Ride & Run - Gouna', start: "2021-08-19T08:30:00", end: "2021-08-19T09:30:00", url: '/class/63', color: "#ea8b53" },
        { title: 'Yoga - Gouna', start: "2021-08-19T10:00:00", end: "2021-08-19T11:00:00", url: '/class/64', color: "#ea8b53" },
        //Friday
        { title: 'GHM Training Program - Gouna', start: "2021-08-20T08:30:00", end: "2021-08-20T09:30:00", url: '/class/71', color: "#ea8b53" },
        { title: 'Personal Traingin & Customized Classes - Gouna', start: "2021-08-20T10:00:00", end: "2021-08-20T19:30:00", url: '/class/70', color: "#ea8b53" },
        //Saturday
        { title: 'Yoga - Gouna', start: "2021-08-21T10:00:00", end: "2021-08-21T11:00:00", url: '/class/64', color: "#ea8b53" },
        { title: 'Boxing - Gouna', start: "2021-08-21T17:30:00", end: "2021-08-21T18:30:00", url: '/class/34', color: "#ea8b53" },
        { title: 'MMA - Gouna', start: "2021-08-21T18:30:00", end: "2021-08-21T19:30:00", url: '/class/68', color: "#ea8b53" },
        //Sunday
        { title: 'Ride & Run - Gouna', start: "2021-08-22T08:30:00", end: "2021-08-22T09:30:00", url: '/class/63', color: "#ea8b53" },
        { title: 'Yoga - Gouna', start: "2021-08-22T10:00:00", end: "2021-08-22T11:00:00", url: '/class/64', color: "#ea8b53" },
        { title: 'Hip Hop - Gouna', start: "2021-08-22T18:30:00", end: "2021-08-22T19:30:00", url: '/class/65', color: "#ea8b53" },
        //Monday
        { title: 'Functional Strength - Gouna', start: "2021-08-23T08:30:00", end: "2021-08-23T09:30:00", url: '/class/66', color: "#ea8b53" },
        { title: 'Zumba - Gouna', start: "2021-08-23T10:00:00", end: "2021-08-23T11:00:00", url: '/class/31', color: "#ea8b53" },
        { title: 'Pound - Gouna', start: "2021-08-23T17:30:00", end: "2021-08-23T18:30:00", url: '/class/67', color: "#ea8b53" },
        { title: 'MMA - Gouna', start: "2021-08-23T18:30:00", end: "2021-08-23T19:30:00", url: '/class/68', color: "#ea8b53" },
        //Tuesday
        { title: 'Yoga - Gouna', start: "2021-08-24T08:30:00", end: "2021-08-24T09:30:00", url: '/class/64', color: "#ea8b53" },
        { title: 'SPINNING - Gouna', start: "2021-08-24T10:00:00", end: "2021-08-24T11:00:00", url: '/class/32', color: "#ea8b53" },
        { title: 'Boxercise - Gouna', start: "2021-08-24T17:30:00", end: "2021-08-24T18:30:00", url: '/class/69', color: "#ea8b53" },
        //Wednesday
        { title: 'Pound - Gouna', start: "2021-08-25T10:00:00", end: "2021-08-25T11:00:00", url: '/class/67', color: "#ea8b53" },
        { title: 'HIIT - Gouna', start: "2021-08-25T17:30:00", end: "2021-08-25T18:30:00", url: '/class/19', color: "#ea8b53" },
        { title: 'Hip Hop - Gouna', start: "2021-08-25T18:30:00", end: "2021-08-25T19:30:00", url: '/class/65', color: "#ea8b53" },
        //Thursday
        { title: 'Ride & Run - Gouna', start: "2021-08-26T08:30:00", end: "2021-08-26T09:30:00", url: '/class/63', color: "#ea8b53" },
        { title: 'Yoga - Gouna', start: "2021-08-26T10:00:00", end: "2021-08-26T11:00:00", url: '/class/64', color: "#ea8b53" },
        
    ];

    return (
        isLoading ?


            <div className="loaderWrapper">
                <ClipLoader
                    css={spinner}
                    size={150}
                    color={"#fada4d"}
                />
            </div>


            :


            <React.Fragment>
                <Container maxWidth="xl">
                    <GridRow>
                        <Grid container spacing={3}>
                            <Grid item xs>
                                <h2 className="h1 blue calendar-title">{ class_title !== '' || class_title !== undefined || class_title !== null ? class_title: ''}</h2>
                                <Grid item lg="6">
                                    <p dangerouslySetInnerHTML={{__html: (class_description !== '' && class_description !== undefined && class_description !== null )? class_description.replace(/\r\n|\r|\n/g,"<br />") : ""}}></p>
                                </Grid>
                                <div className={styles.calWrapper}>
                                    <FullCalendar
                                        plugins={[dayGridPlugin, timeGridPlugin]}
                                        initialView="timeGridDay"
                                        contentHeight="500px"
                                        scrollTime="08:00:00"

                                        // events={[
                                        //     { title: 'event 1', start: "2021-05-05T00:00:00", end: "2021-05-05T02:00:00", url: 'class'},
                                        //     { title: 'event 2', start: "2021-05-06T00:00:00", end: "2021-05-06T02:00:00", url: 'class' },
                                        //     { title: 'event 3', start: "2021-05-07T00:00:00", end: "2021-05-07T02:00:00", url: 'class' },
                                        //     { title: 'event 4', start: "2021-05-08T00:00:00", end: "2021-05-08T02:00:00", url: 'class' },
                                        //     { title: 'event 5', start: "2021-05-09T00:00:00", end: "2021-05-09T02:00:00", url: 'class' },
                                        //     { title: 'event 6', start: "2021-05-27T00:00:00", end: "2021-05-27T02:00:00", url: 'class', extendedProps: {instructor: 'Ashraqat'}, description: 'Ashraqat' },
                                        //   ]}

                                        events={eventsArray}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </GridRow>
                </Container>
            </React.Fragment>

    )
}
